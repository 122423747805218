<template>
    <div class="paymentplan-emails">
        <el-alert
            v-if="warning_message"
            type="error"
            :closable="false"
            :class="{'has-contacts': contacts.length}"
        >
            {{ warning_message }}
        </el-alert>

        <template v-if="contacts.length">
            <div class="paymentplan-emails-header">
                <el-row :gutter="5" type="flex" align="middle">
                    <el-col :span="10" class="description">
                        Contact
                    </el-col>

                    <el-col :span="10" class="email">
                        Email
                    </el-col>

                    <el-col :span="2" class="center">
                        Select
                    </el-col>

                    <el-col :span="2" class="center">
                        Address to
                    </el-col>
                </el-row>
            </div>
            <div class="paymentplan-emails-client">
                <el-row
                    class="contact-row"
                    :gutter="5"
                    type="flex"
                    align="middle"
                    v-for="contact of client_contacts"
                    :key="`contact_${contact.email}`"
                >
                    <el-col :span="10" class="description">
                        {{ contact.name }}
                    </el-col>

                    <el-col :span="10" class="description">
                        {{ contact.email }}
                    </el-col>

                    <el-col :span="2" class="center">
                        <el-checkbox v-model="contact.selected" />
                    </el-col>

                    <el-col :span="2" class="center">
                        <el-radio :label="contact.email" v-model="addressee">
                            -
                        </el-radio>
                    </el-col>
                </el-row>
            </div>
            <div class="paymentplan-emails-users">
                <el-row
                    class="contact-row"
                    :gutter="5"
                    type="flex"
                    align="middle"
                    v-for="contact of user_contacts"
                    :key="`user_${contact.email}`"
                >
                    <el-col :span="10" class="description">
                        {{ contact.name }}
                        <el-tag v-if="contact.role" type="info" size="mini">
                            {{ contact.role }}
                        </el-tag>
                    </el-col>

                    <el-col :span="10" class="description">
                        {{ contact.email }}
                    </el-col>

                    <el-col :span="2" class="center">
                        <el-checkbox v-model="contact.selected" />
                    </el-col>
                </el-row>
            </div>
        </template>
    </div>
</template>

<script>
import {EMAIL_TYPE} from '@/enums';

export default {
    name: 'paymentplan-emails',
    props: {
        payment_plan: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            client_contacts: [],
            user_contacts: [],
            addressee: null,
        };
    },
    computed: {
        estimate() {
            /**
             * TODO: This should be improved!
             * payment_plan.estimate can be a "Ref String" or a "Firebase Ref Object"
             * - While creating a PP, payment_plan.estimate is a Firebase Ref object
             * - If the PP is already created, and we go to modify the contacts, payment_plan.estimate is just a string pointing to the ref
             */
            return this.$store.getters.estimateWithId(
                this.payment_plan.estimate?.id ?? this.payment_plan.estimate
            );
        },
        client() {
            return this.$store.getters.clientWithId(this.estimate.client);
        },
        project() {
            return this.$store.getters.projectWithId(this.estimate.project);
        },
        plan_contacts() {
            return this.payment_plan.contact_emails;
        },
        contacts() {
            // combine client & user contacts, mapped to the correct addressing type
            return [
                ...this.client_contacts
                    .filter((contact) => contact.selected)
                    .map((contact) => ({
                        name: contact.name,
                        email: contact.email,
                        type:
                            contact.email === this.addressee
                                ? EMAIL_TYPE.ADDRESSEE
                                : EMAIL_TYPE.CC,
                    })),
                ...this.user_contacts
                    .filter((contact) => contact.selected)
                    .map((contact) => ({
                        name: contact.name,
                        email: contact.email,
                        type: EMAIL_TYPE.BCC,
                    })),
            ];
        },
        warning_message() {
            if (!this.contacts.length)
                return 'At least one contact email is required for a payment plan';
            if (!this.contacts.some((c) => c.type === EMAIL_TYPE.ADDRESSEE))
                return 'Please select a contact as the addressee for invoicing';
            return null;
        },
    },
    watch: {
        client: {
            // Process client data when available
            handler(val) {
                if (val) {
                    // Create an array with the primary client contact
                    // using the client's first name and email
                    const contacts = [
                        {
                            name: val.first_name || null,
                            email: val.email,
                            // If plan contacts exist, check if this client is already selected;
                            // otherwise, select by default
                            selected: this.plan_contacts
                                ? this.isContactSelected(val)
                                : true,
                        },
                    ];

                    // If the client has additional contacts, add them to the contacts array
                    if (val.additional_contacts) {
                        contacts.push(
                            ...val.additional_contacts.map((c) => ({
                                name: c.first_name || null,
                                email: c.email,
                                // Similarly, check if each additional contact is selected
                                selected: this.plan_contacts
                                    ? this.isContactSelected(c)
                                    : true,
                            }))
                        );
                    }

                    // Deduplicate contacts based on email to ensure uniqueness
                    this.client_contacts = contacts.filter(
                        (c, index, list) =>
                            list.findIndex((c2) => c2.email === c.email) ===
                            index
                    );

                    // Determine the designated addressee from the plan contacts
                    const selected = this.getContactAddressee();
                    if (selected) {
                        // Find a matching contact from the deduplicated client contacts
                        const match = this.client_contacts.find(
                            (c) => c.email === selected.email
                        );
                        // If a match is found, set the addressee to the matched contact's email
                        if (match) {
                            this.addressee = match.email;
                        }
                    }
                }
            },
            immediate: true,
        },
        project: {
            handler(val) {
                if (!val) return;

                const currentUser = this.$store.getters.user;
                const creatingUser = this.$store.getters.userWithId(
                    this.$options.filters.fireRef2id(
                        this.payment_plan.created_by
                    )
                );

                const contacts = [];

                // Include current user if not already in contact list
                const currentUserAlreadyListed = this.plan_contacts?.some(
                    (c) => c.email === currentUser.email
                );
                if (!currentUserAlreadyListed) {
                    contacts.push({
                        name: currentUser.name,
                        role: 'You',
                        email: currentUser.email,
                        selected: this.plan_contacts
                            ? this.isContactSelected(currentUser)
                            : true,
                    });
                }

                // Add team members
                if (val.team?.length) {
                    for (const t of val.team) {
                        const user = this.$store.getters.userWithId(
                            this.$options.filters.fireRef2id(t.user)
                        );
                        const role = this.$store.getters.userRoleWithId(
                            this.$options.filters.fireRef2id(t.role)
                        );

                        contacts.push({
                            name: user.name,
                            role:
                                user.email === creatingUser?.email
                                    ? 'Payment plan creator'
                                    : role?.label ?? null,
                            email: user.email,
                            selected: this.plan_contacts
                                ? this.isContactSelected(user)
                                : role?.value === 'product_owner',
                        });
                    }
                }

                // Deduplicate and filter invalid
                this.user_contacts = contacts.filter(
                    (c, index, list) =>
                        c.email &&
                        list.findIndex((c2) => c2.email === c.email) === index
                );
            },
            immediate: true,
        },
        contacts: {
            handler(val) {
                this.$emit('change', val);
            },
            immediate: true,
        },
    },
    methods: {
        isContactSelected(contact) {
            if (this.plan_contacts) {
                return this.plan_contacts.some(
                    (c) => c.email === contact.email
                );
            }
            return false;
        },
        getContactAddressee() {
            if (this.plan_contacts) {
                return this.plan_contacts.find(
                    (c) => c.type === EMAIL_TYPE.ADDRESSEE
                );
            }
            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
.paymentplan-emails {
    min-width: 810px;
    padding: 20px;
    margin-top: 10px;
    border-radius: 4px;
    border: 1px solid $border-grey-light;
    background-color: $grey;

    .el-alert.has-contacts {
        margin-bottom: 20px;
    }

    .contact-row {
        border-top: 1px solid $border-grey;
        padding: 10px;
        font-size: 13px;
        ::v-deep .el-radio__label {
            display: none;
        }
        .el-tag {
            margin-left: 10px;
            font-size: 10px;
        }
    }

    .center {
        text-align: center;
    }

    .paymentplan-emails-header {
        font-size: 11px;
        padding: 0 10px 5px;
    }

    .paymentplan-emails-users {
        .contact-row:first-child {
            border-top-width: 3px;
        }
    }
}
</style>
